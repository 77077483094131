import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our Pop Up Mobility classes with Glenn will start this Wednesday,
March 6th, at 11:30am and next Sunday, March 10th, at 12:00am.  This is
free for all Unlimited members, \\$10 for anyone else, so pop in if you
can make it!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit the Ville day at Climb Nulu is this Saturday at 11:30.  We
have a sign up sheet by the white board so let us know if you can make
it.  If you have any questions email Daniel.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      